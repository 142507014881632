import React, { Fragment } from "react"
import styled from "@emotion/styled"
import { graphql } from "gatsby"
import { injectIntl, Link } from "gatsby-plugin-intl"
// import { Link } from "gatsby"

import {
  SectionContainer,
  SectionInnerWrapper,
} from "../../../../components/Layout/styled"

import Footer from "../../../../components/Layout/Footer"

const Billing = ({ intl, data }) =>
  data &&
  data.allDirectusUserDashboardTranslation.nodes.length > 0 && (
    <Fragment>
      <SectionContainer>
        <SectionInnerWrapper>
          <RowWrapper>
            <ColumnWrapper>
              <h3>
                {
                  data.allDirectusUserDashboardTranslation.nodes[0]
                    .billing_address_string
                }
              </h3>
            </ColumnWrapper>
          </RowWrapper>
          <RowWrapper>
            <ColumnWrapper>
              <RowGroup>
                <Column>
                  <Label>
                    {
                      data.allDirectusUserDashboardTranslation.nodes[0]
                        .first_name_string
                    }{" "}
                    <abbr title="required">*</abbr>
                  </Label>
                  <Field>
                    <input type="text" name="firstname" />
                  </Field>
                </Column>
                <Column>
                  <Label>
                    {
                      data.allDirectusUserDashboardTranslation.nodes[0]
                        .last_name_string
                    }{" "}
                    <abbr title="required">*</abbr>
                  </Label>
                  <Field>
                    <input type="text" name="firstname" />
                  </Field>
                </Column>
              </RowGroup>
              <RowGroup>
                <Column>
                  <Label>
                    {
                      data.allDirectusUserDashboardTranslation.nodes[0]
                        .company_name_string
                    }
                  </Label>
                  <Field>
                    <input type="text" name="firstname" />
                  </Field>
                </Column>
              </RowGroup>
              <RowGroup>
                <Column>
                  <Label>
                    {
                      data.allDirectusUserDashboardTranslation.nodes[0]
                        .country_string
                    }{" "}
                    <abbr title="required">*</abbr>
                  </Label>
                  <Field>
                    <input type="text" name="firstname" />
                  </Field>
                </Column>
              </RowGroup>
              <RowGroup>
                <Column>
                  <Label>
                    {
                      data.allDirectusUserDashboardTranslation.nodes[0]
                        .apartment_address_string
                    }
                  </Label>
                  <Field>
                    <input type="text" name="firstname" />
                  </Field>
                </Column>
                <Column>
                  <Label>
                    {
                      data.allDirectusUserDashboardTranslation.nodes[0]
                        .street_address_string
                    }{" "}
                    <abbr title="required">*</abbr>
                  </Label>
                  <Field>
                    <input type="text" name="firstname" />
                  </Field>
                </Column>
              </RowGroup>
              <RowGroup>
                <Column>
                  <Label>
                    {
                      data.allDirectusUserDashboardTranslation.nodes[0]
                        .city_name_string
                    }{" "}
                    <abbr title="required">*</abbr>
                  </Label>
                  <Field>
                    <input type="text" name="firstname" />
                  </Field>
                </Column>
              </RowGroup>
              <RowGroup>
                <Column>
                  <Label>
                    {
                      data.allDirectusUserDashboardTranslation.nodes[0]
                        .state_name_string
                    }{" "}
                    <abbr title="required">*</abbr>
                  </Label>
                  <Field>
                    <input type="text" name="firstname" />
                  </Field>
                </Column>
              </RowGroup>
              <RowGroup>
                <Column>
                  <Label>
                    {
                      data.allDirectusUserDashboardTranslation.nodes[0]
                        .address_postcode_string
                    }{" "}
                    <abbr title="required">*</abbr>
                  </Label>
                  <Field>
                    <input type="text" name="firstname" />
                  </Field>
                </Column>
              </RowGroup>
              <RowGroup>
                <Column>
                  <Label>
                    {
                      data.allDirectusUserDashboardTranslation.nodes[0]
                        .phone_string
                    }{" "}
                    <abbr title="required">*</abbr>
                  </Label>
                  <Field>
                    <input type="text" name="firstname" />
                  </Field>
                </Column>
              </RowGroup>
              <RowGroup>
                <Column>
                  <Label>
                    {
                      data.allDirectusUserDashboardTranslation.nodes[0]
                        .email_address_string
                    }{" "}
                    <abbr title="required">*</abbr>
                  </Label>
                  <Field>
                    <input type="text" name="firstname" />
                  </Field>
                </Column>
              </RowGroup>
              <RowGroup>
                <Column>
                  <SubmitButton>
                    {
                      data.allDirectusUserDashboardTranslation.nodes[0]
                        .save_address_button
                    }
                  </SubmitButton>
                </Column>
              </RowGroup>
            </ColumnWrapper>
          </RowWrapper>
        </SectionInnerWrapper>
      </SectionContainer>

      {data.allDirectusFooterTranslation.nodes.length > 0 && (
        <Footer data={data.allDirectusFooterTranslation.nodes[0]} />
      )}
    </Fragment>
  )

const RowWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-center: flex-start;
  align-center: flex-start;
  max-width: 1080px;
  width: 100%;
`

const ColumnWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-center: flex-start;
  align-center: flex-start;
  width: 100%;
  color: #777;

  > h3 {
    font-family: "GothamLight";
    margin-bottom: 0.5em;
    color: #262626;
    font-weight: Lighter;
    font-size: 1em;
    line-height: 1.4em;
  }

  > p {
    color: #777;
    font-size: 14px;
    line-height: 1.3em;
    margin-bottom: 1.3em;
  }
`

const RowGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-center: flex-start;
  align-center: flex-start;
  width: 100%;
  margin-bottom: 0.5em;
  flex-direction: row;
`

const Column = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-center: flex-start;
  align-center: flex-start;
  width: 100%;
  flex: 1;
  margin-right: 4%;
`

const Label = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-center: flex-start;
  align-center: flex-start;
  width: 100%;
  margin-bottom: 0.5em;
  font-weight: bold;
  display: block;
  font-size: 0.9em;
  margin-bottom: 0.4em;
  color: #222;

  > abbr {
    color: #b20000;
  }
`

const Field = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-center: flex-start;
  align-center: flex-start;
  width: 100%;
  color: #777;

  > input {
    width: 100%;
    margin-top: 0px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    background-color: #ede9e8;
    border-radius: 0px;
    height: 2.507em;
    font-size: 0.97em;
    padding: 0px 12px;
  }
`

const SubmitButton = styled.button`
  position: relative;
  display: inline-block;
  background-color: #262626;
  text-transform: uppercase;
  font-size: 0.97em;
  letter-spacing: 0.03em;
  touch-action: none;
  cursor: pointer;
  font-weight: bolder;
  text-align: center;
  color: #fff;
  vertical-align: middle;
  margin-top: 0px;
  margin-right: 1em;
  text-shadow: none;
  line-height: 2.4em;
  min-height: 2.5em;
  max-width: 100%;
  text-rendering: optimizelegibility;
  box-sizing: border-box;
  text-decoration: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-image: initial;
  border-radius: 0px;
  padding: 0px 1.2em;
  font-weight: bold;
`

export const query = graphql`
  query($lang: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allDirectusUserDashboardTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        billing_address_string
        first_name_string
        last_name_string
        company_name_string
        country_string
        apartment_address_string
        street_address_string
        city_name_string
        state_name_string
        address_postcode_string
        phone_string
        email_address_string
        save_address_button
        language
      }
    }
    allDirectusFooterTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        facebook
        gbp_facebook
        youtube
        twitter
        instagram
        linkedin
        about_us {
          name
          list {
            link
            list
          }
        }
        contact {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        purchases {
          list {
            list
            link
          }
          name
        }
        help {
          list {
            link
            list
          }
          name
        }
        about_us_menu {
          name
          list {
            link
            list
          }
        }
        knowledge_center_menu {
          name
          list {
            link
            list
          }
        }
        shop_menu {
          name
          list {
            link
            list
          }
        }
        support_menu {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        newsletter_heading
        follow_us_heading
        footer_bottom {
          list {
            link
            list
          }
        }
      }
    }
  }
`

export default injectIntl(Billing)
